export default [
//   {
//     path: '/dashboard/analytics',
//     name: 'dashboard-analytics',
//     component: () => import('@/views/dashboard/analytics/Analytics.vue'),
//   },
  {
    path: '/apps/employee/list',
    name: 'dashboard-ecommerce',
    component: () => import('../../views/apps/invoice/invoice-list/InvoiceList.vue'),
  }
]

<template>
    <div class="wrap-loading" :style="`width: ${previewWidth}; height: ${previewHeight};    margin: 0 auto;`" v-if="value === undefined || loading">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>

    <div class="wrap" :style="`width: ${previewWidth}; height: ${previewHeight};  margin: 0 auto;`" v-else>
      
        <input
            type="file"
            @change="onFileChange"
            id="uploader"
            accept="image/png, image/jpeg"
        />
        
        <div class="card card-custom overlay" v-if="url">
            <div class="card-body p-0">

                <div class="overlay-wrapper text-center" >
                    <img :src="url" alt="" class="w-100 rounded"/>
                </div>

                <div class="overlay-layer">
                    <button type="button" class="btn btn-clean btn-icon mr-2 upload-button">
                        <input
                            type="file"
                            @change="onFileChange"
                            accept="image/png, image/jpeg"
                        />
                        <i class="far fa-edit icon-lg text-success "  style="font-size: 20px; color: red; background-color: #F2F2F2"></i>
                    </button>
                    <button @click="onImageRemoveClick" type="button" class="btn btn-clean btn-icon">
                        <i class="fas fa-trash-alt icon-lg text-success" style="font-size: 20px; color: red; background-color: #F2F2F2" ></i>
                    </button>
                </div>

            </div>
        </div>

        <div class="d-flex align-items-center justify-content-center preview-default"  style=" background-color:#F2F2F2" >
        <!-- <div class="d-flex align-items-center justify-content-center preview-default" v-else> -->
            <img src="./add.png" class="text-center" style=" width: 15px; height: 15px; margin: 42px 42px;" alt="default preview">
        </div>
    </div>

</template>

<script>
import axios from "axios"

const _URL = window.URL || window.webkitURL

export default {
    props: ['width', 'height', 'size', 'value', 'previewWidth', 'previewHeight', 'uploadUrl', 'ratio', 'token'],
    data() {
        return {
            url: null,
            loading: false,
            
        }
    },
    mounted() {
        if(this.value !== undefined) {
            this.url = this.value
        }
    },
    updated() {
        if(this.value !== undefined) {
            this.url = this.value
        }
    },
    methods: {
        async onFileChange(e) { 
        console.log("1")
            this.loading = true
            const file = e.target.files[0];
            
            if(file.size > (1 * 5000000)) {
                
                this.$toast.error(`กรุณาอัปโหลดรูปที่มีขนาดไม่เกิน 5 MB`);
                
                this.loading = false
                return
            }
            console.log("2")

            if(file.type === 'image/png' || file.type === 'image/jpeg') {
  console.log("3")
                if(this.ratio) {
                    const _img_detail = await this.getWidthHeightImg(file)
                    const imgDetailRatio = _img_detail.width / _img_detail.height

                    if(this.ratio === '1:1') {
                        const defaultRatio = 1/1
                        if(imgDetailRatio.toFixed(1) != defaultRatio.toFixed(1)) {
                            this.$toast.error(`Image size must be 300 x 300, 500 x 500, 800 x 800`)
                            this.loading = false
                            return
                        }
                    }
                    else if(this.ratio === '4:3') {
                        const defaultRatio = 4/3
                        if(imgDetailRatio.toFixed(1) != defaultRatio.toFixed(1)) {
                            this.$toast.error(`Image ratio must be 4:3`)
                            this.loading = false
                            return
                        }
                    }
                    else if(this.ratio === '5:4') {
                        const defaultRatio = 5/4
                        if(imgDetailRatio.toFixed(1) != defaultRatio.toFixed(1)) {
                            this.$toast.error(`Image ratio must be 5:4`)
                            this.loading = false
                            return
                        }
                    }
                    else if(this.ratio === '4:5') {
                        const defaultRatio = 4/5
                        if(imgDetailRatio.toFixed(1) != defaultRatio.toFixed(1)) {
                            this.$toast.error(`Image ratio must be 64 x 80, 678 x 848, 1080 x 1350`)
                            this.loading = false
                            return
                        }
                    }
                    else if(this.ratio === '3:2') {
                        const defaultRatio = 3/2
                        if(imgDetailRatio.toFixed(1) != defaultRatio.toFixed(1)) {
                            this.$toast.error(`Image size must 900 x 600`)
                            this.loading = false
                            return
                        }
                    }
                    else if(this.ratio === '8:5') {
                        const defaultRatio = 8/5
                        if(imgDetailRatio.toFixed(1) != defaultRatio.toFixed(1)) {
                            this.$toast.error(`Image size must be 1598 × 1024`)
                            this.loading = false
                            return
                        }
                    }
                    else if(this.ratio === '16:9') {
                        const defaultRatio = 16/9
                        if(imgDetailRatio.toFixed(1) != defaultRatio.toFixed(1)) {
                            this.$toast.error(`Image size must be 960 x 540, 1024 x 576, 1280 x 720`)
                            this.loading = false
                            return
                        }
                    }
                    else if(this.ratio === '2.4:1') {
                        const defaultRatio = 2.4/1
                        if(imgDetailRatio.toFixed(1) != defaultRatio.toFixed(1)) {
                            this.$toast.error(`Image size must be 1280 x 533, 1920 x 800`)
                            this.loading = false
                            return
                        }
                    }
                    else {
                        if(this.width && this.height) {
                            const defaultRatio = this.width/this.height
                            if(imgDetailRatio.toFixed(1) != defaultRatio.toFixed(1)) {
                                this.$toast.error(`Image size must be ${this.width} x ${this.height}`)
                                this.loading = false
                                return
                            }
                        }
                    }
                }
                let reader = new FileReader()
                reader.onload = async (event) => {
                    console.log("4",event)
                    this.url = event.target.result
                    this.$emit('input', event.target.result, file.name)
                    this.$emit('apiResponse', event.target.result)
                    this.loading = false
                    // axios.post(this.uploadUrl || '', {
                    //     img_url :event.target.result
                    // }, {
                    //     headers: {
                    //         Authorization: this.token || ''
                    //     }
                    // })
                    //     .then(response => {
                    //         console.log(response)
                    //         this.url = response.data.data
                    //         this.$emit('input', response.data.data, file.name)
                    //         this.$emit('apiResponse', response.data)
                    //         this.loading = false
                    //     })
                    //     .catch(err => {
                    //         if(err.response.data) {
                    //             this.$emit('errorResponse', err.response.data)
                    //         }
                    //         this.loading = false
                    //     })
                }
                reader.readAsDataURL(file)
            }
            else {
                this.$toast.error(`Image format must be .png .jpeg`)
                this.loading = false
            }
        },
        onImageRemoveClick() {
            this.url = ''
            document.getElementById('uploader').value = ''
            this.$emit('remove')
        },
        getWidthHeightImg(file) {
            return new Promise(function (resolve, reject) {
                try {
                    let _img = new Image();
                    _img.src = _URL.createObjectURL(file);
                    _img.onload = function() {
                        resolve({
                            width: _img.width,
                            height: _img.height
                        });
                    };
                } catch (ex) {
                    resolve({
                        width: 9999999,
                        height: 9999999
                    });
                }
            })
        }
    },
}
</script>

<style scoped>

.wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #C4C4C4;
    cursor: pointer;
}
.wrap:hover {
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
}

.upload-button input,
.wrap input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
}
.upload-button input[type=file],
.upload-button input[type=file]::-webkit-file-upload-button,
.wrap input[type=file],
.wrap input[type=file]::-webkit-file-upload-button {
    cursor: pointer; 
}
.preview-default {
    width: 100%;
    height: 100%;
    padding: 0px;
}
.preview-default img {
   width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.card {
    width: 100%;
    height: 100%;
}
.overlay-wrapper {
    width: 100%;
    height: 100%;
    padding: 3px;
}
.overlay-wrapper img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.overlay-layer {
    background-color: rgba(0, 0, 0, 0.041);
    font-size: 40px;
}
.upload-button {
    position: relative;
    overflow: hidden;
}
.wrap-loading {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #C4C4C4;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #27BA08;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
</style>
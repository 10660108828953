import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import {vue3JsonExcel} from "vue3-json-excel"
import UploadImagePreview from '../src/views/components/upload/UploadImagePreview.vue'

import JsonExcel from 'vue-json-excel'

// import JsonExcel from "vue-json-excel3";
// Vue.component("downloadExcel", JsonExcel);
Vue.component("downloadExcel", JsonExcel);
Vue.component("vue3JsonExcel", vue3JsonExcel)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component("UploadImagePreview", UploadImagePreview)
